import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";
import {
  View,
  StyleSheet,
  Text,
  Linking
} from 'react-native';
import {BrowserView, MobileView} from 'react-device-detect';


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #a981ca;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 4px 0px 2px rgba(10, 10, 10, 1.4);
  -webkit-box-shadow: -.1em 1em 2em .1em rgba(85, 3, 155, 1.5);
  -moz-box-shadow: -.5em 1em 2em 1em rgba(10, 10, 10, 1.5);
  :hover {
    background-color: #480480;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;



export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #3B04A0;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 15px 0px -2px rgba(200, 200, 200, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(200, 200, 200, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(200, 200, 200, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 1
  0%;
  align-self: center;
  @media (min-width: 367px) {
    flex-direction: row;
  }
`;



export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: none;
  background:radial-gradient(farthest-corner at 40px 40px,
    #FFFFFF 30%, #FFFFFF 50%);
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 360px;
  @media (max-width: 767px) {
    width: 360px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogo2 = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const LogoDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-content: center;
gap: 10%;
width: 300px;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid black;
  background-color: black;
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImg2 = styled.img`
  
width: 100px;
@media (min-width: 30px) {
  width: 100px;
}
@media (min-width: 800px) {
  width: 500px;
}

  transition: width 0.5s;
`;

export const StyledImg3 = styled.img`


position: absolute;
bottom: 10px;
left: 20px;
  
  width: 150px;
  @media (min-width: 900px) {
    width: 200px;
  }
  @media (min-width: 1000px) {
    width: 200px;
  }
  transition: width 0.5s;
`;


export const StyledLink = styled.a`
  color: #a981ca;
  font: 
  text-decoration: none;
  :hover {
    color: #9d70c3;
  }
`;

export const StyledLink2 = styled.a`
  color: #032398;
  font: 
  text-decoration: none;
  :hover {
    color: #9d70c3;
  }
`;

export const StyledHR = styled.hr`
  border: 2px solid white;  
  background-color: white;
  border-radius: 2px;
  width: 400px;
  @media (max-width: 567px) {
    width: 250px;
  }
`;

export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 10px;
 
  background:#a981ca;
  //padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 0px 2px rgba(10, 10, 10, 1.4);
  -webkit-box-shadow: -.5em 1em 2em 1em rgba(85, 3, 155, 1.5);
  -moz-box-shadow: -.5em 1em 2em 1em rgba(10, 10, 10, 1.5);
  :hover {
    background-color: #480480;
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Connect Wallet");
  const [claimingNft, setClaimingNft] = useState(false);
  const [StakeNFT, setStakeNFT] = useState(false);
  const [UnStakeNFT, setUnStakeNFT] = useState(false);
  const [UnStakeAllNFT, setUnStakeAllNFT] = useState(false);
  const [ClaimReward, setClaimReward] = useState(false);
  const [CheckReward, setCheckReward] = useState(false);
  const [CheckIDS, setCheckIDS] = useState(false);
  const [feedback, setFeedback] = useState(`Approve The Contract For Staking `);
  const [isApproved, setisApproved] = useState(false);
  const [StakeMSG, setMSG] = useState(null);
  const [UnStakeAllMSG, setUnStakeAllMSG] = useState(null);
  const [UnStakeMSG, setUnMSG] = useState(null);
  const [HavestMSG, setHarvestMSG] = useState(null);
  const [RewardMSG, setRewardMSG] = useState(null);
  const [CheckMSG, setCheckMSG] = useState(null);
  const [tokens, settokens] = useState();
  const tokensInputRef = useRef();
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const Approve = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setFeedback(`Approving your Staking...`);
    setClaimingNft(true);
    blockchain.smartToken.methods
    .setApprovalForAll(CONFIG.CONTRACT_ADDRESS, true)
    .send({
      to: CONFIG.TOKEN,
      from: blockchain.account,
    })


      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You can Now Stake Your NFT`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const Stake = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setMSG(`Staking your ${CONFIG.SYMBOL} #${tokens}...`);
    setStakeNFT(true);
    blockchain.smartContract.methods
      .deposit([tokens])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })

      .once("error", (err) => {
        console.log(err);
        setMSG("Sorry, Something Went Wrong");
        setStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setMSG(
          `You Succesfully Staked Your ${CONFIG.NFT_NAME} `
        );
        setStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const StakeAll = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    setMSG(`Staking All of your NFTs...`);
    setStakeNFT(true);
    blockchain.smartToken.methods
    .walletOfOwner(blockchain.account)
    .call()
    .then((receipt) => {
      console.log("Token Ids of User", receipt);
      let TokenidofUser = receipt;
      let totalGasLimit = String(gasLimit * TokenidofUser.length);
    blockchain.smartContract.methods
      .deposit(TokenidofUser)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setMSG("Sorry, Something Went Wrong");
        setStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setMSG(
          `You Succesfully Staked Your ${CONFIG.NFT_NAME} `
        );
        setStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
    });
  };

  const unstakeallnfts = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    setUnStakeAllMSG(`Unstaking All of your NFTs...`);
    setUnStakeAllNFT(true);
    blockchain.smartContract.methods
    .depositsOf(blockchain.account)
    .call({
      from: blockchain.account,
    })
    .then((receipt) => {
      console.log(receipt);
      setCheckMSG(
        `TokenID #${receipt}`
      );
      let TID = receipt;
      setCheckIDS(false);
      dispatch(fetchData(blockchain.account));
      let totalGasLimit = String(gasLimit * TID.length);
    blockchain.smartContract.methods
    .withdraw(TID)
    .send({
      gasLimit: String(totalGasLimit),
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
    })
      .once("error", (err) => {
        console.log(err);
        setUnStakeAllMSG("Sorry, something went wrong please try again later.");
        setClaimReward(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setUnStakeAllMSG(
          `All of Your NFTs are Unstaked`
        );
        setClaimReward(false);
        dispatch(fetchData(blockchain.account));
      });
    });
  };

  const UnStake = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setUnMSG(`UnStaking your ${CONFIG.SYMBOL} #${tokens}...`);
    setUnStakeNFT(true);
    blockchain.smartContract.methods
      .withdraw([tokens])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })

      .once("error", (err) => {
        console.log(err);
        setUnMSG("Sorry, you have reached your wallet limit");
        setUnStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setUnMSG(
          `You Succesfully UnStaked ${CONFIG.SYMBOL} #${tokens}`
        );
        setUnStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };



  const Harvest = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setHarvestMSG(`Claiming your Rewards...`);
    setClaimReward(true);
    blockchain.smartContract.methods
    .depositsOf(blockchain.account)
    .call({
      from: blockchain.account,
    })
    .then((receipt) => {
      console.log(receipt);
      setCheckMSG(
        `TokenID #${receipt}`
      );
      let TID = receipt;
      setCheckIDS(false);
      dispatch(fetchData(blockchain.account));
    
    blockchain.smartContract.methods
    .claimRewards(TID)
    .send({
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
    })
      .once("error", (err) => {
        console.log(err);
        setHarvestMSG("Sorry, something went wrong please try again later.");
        setClaimReward(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setHarvestMSG(
          `Rewards Harvested`
        );
        setClaimReward(false);
        dispatch(fetchData(blockchain.account));
      });
    });
  };



  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(blockchain.account.substring(0,4) + "..." + blockchain.account.substring(38,42));

      blockchain.smartContract.methods
      .depositsOf(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        let TOTALS = receipt;
        setCheckMSG(
          `#${receipt}`
        );
        blockchain.smartContract.methods
        .calculateRewards(blockchain.account, TOTALS)
        .call({
          from: blockchain.account,
        })
        .then((receipt) => {
          let ALLo = receipt.map(RW => {
            return (
              String(RW) / 1e9
            )
          });
          function sum(input){
            if (toString.call(input) !== "[object Array]")
               return false;
                       var total =  0;
                       for(var i=0;i<input.length;i++)
                         {                  
                           if(isNaN(input[i])){
                           continue;
                            }
                             total += Number(input[i]);
                          }
                        return total;
                       }
          console.log("TOTAL REWARD", Number(sum(ALLo)).toLocaleString('en-US'));
           setRewardMSG(
            `${Number(sum(ALLo)).toLocaleString('en-US')}`
           );
        });
      });


      blockchain.smartToken.methods
      .isApprovedForAll(blockchain.account, CONFIG.CONTRACT_ADDRESS)
      .call({
        to: CONFIG.TOKEN,
        from: blockchain.account,
      })
        .then((receipt) => {
          console.log(receipt);
          setisApproved(true);
        });

    }
  };


  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{backgroundColor: "var(--primary)"}}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <BrowserView>
                <ResponsiveWrapperHeader>

<LogoDiv>
<a href="https://www.donkswap.com/#home" target={"_blank"}>
  <StyledLogo alt={"logo"} src={"/config/images/DonkSwap.png"} />
</a>
</LogoDiv>
<s.SpacerSmall/>
<s.SpacerLarge/>
<s.SpacerLarge/>
<s.SpacerLarge/>
<s.SpacerLarge/>
<s.SpacerLarge/>
<s.SpacerLarge/>
<s.SpacerLarge/>




<s.Headerlinks>
  <s.StyledLink href="https://www.donkswap.com/#home" target={"_blank"}>
    
    Home
  </s.StyledLink >
  <s.StyledLink href="https://www.donkswap.com/#swap" target={"_blank"}>
    DonkSwap
  </s.StyledLink >
  <s.StyledLink href="https://www.donkswap.com/#donk-seal" target={"_blank"}>
    DonkSeal
  </s.StyledLink >
  <s.StyledLink href="https://www.donkswapnfts.com/" target={"_blank"}>
    Donkey NFTs
  </s.StyledLink >
  <s.StyledLink href="https://www.donkswap.com/#casino" target={"_blank"}>
    Donkey King Casino
  </s.StyledLink >
  <s.StyledLink href="https://www.donkswap.com/#mine" target={"_blank"}>
    Donkey Mines
  </s.StyledLink >
  <s.StyledLink href="https://linktr.ee/DonkeyKingFinance" target={"_blank"}>
    More...
  </s.StyledLink >
  <s.SpacerLarge/>
<s.SpacerLarge/>
<s.SpacerLarge/>
<s.SpacerLarge/>


</s.Headerlinks>

<s.HeaderDiv>
<a href="https://t.me/donkeykingegofficial" target={"_blank"}>
<s.Icons src="/config/images/telegram.svg" alt="telegram" />
</a>
  <a href="https://twitter.com/donkeykingeg?s=21&t=x6SQQwG_hKf0X9rmDAsMnQ" target={"_blank"}>
<s.Icons src="/config/images/twitter.svg" alt="twitter" />
</a>
<a href="https://www.instagram.com/donkeykingfinance/" target={"_blank"}>
<s.Icons src="/config/images/instagram.svg" alt="telegram" />
</a>
<a href="https://www.reddit.com/r/DonkFinance/" target={"_blank"}>
<s.Icons src="/config/images/reddit.svg" alt="telegram" />
</a>
<a href="https://github.com/JimmyStax90/DonkSwap" target={"_blank"}>
<s.Icons src="/config/images/github.svg" alt="telegram" />
</a>
<a href="https://www.youtube.com/channel/UC7Ri60pAZO9XJVtTBCg09Hg" target={"_blank"}>
<s.Icons src="/config/images/youtube.svg" alt="discord" />
</a>
<s.SpacerMedium/>
</s.HeaderDiv>

<WalletBox
onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}>
  {blockchain.account !== "" ? (
  <>
  <s.TextSubTitle style={{fontSize: "1rem", color: "#ffffff"}}>
    {walletAddress}
    </s.TextSubTitle>
  </>
  ) : null }
</WalletBox>
</ResponsiveWrapperHeader>
</BrowserView>
<ResponsiveWrapper flex={1}  style={{ padding: 24 }} >

<StyledLogo2 alt={"logo"} src={"/config/images/title.png"} />
</ResponsiveWrapper>
        
 
        <ResponsiveWrapper flex={1}  style={{ padding: 24 }} >
          
        <ResponsiveWrapper flex={1}  style={{ padding: 24 }} >

 
        <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg2
              alt={"example"}
              src={"/config/images/chip.gif"} />
          </s.Container>
 
  
 
  </ResponsiveWrapper>

  
          <s.BOX
         
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
            
              backgroundColor: "rgb(255 255 255 / 20%)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 10px 11px 2px rgba(0,0,0,0.7)",
              
            }}
          >
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT

                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                     <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
        
                    </s.TextDescription>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--POP-text)",
                      }}
                    >
                      ~THIS ONLY NEEDS TO BE DONE THE FIRST TIME~
        
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"column"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          Approve();
                          getData();
                        }}
                      >
                        {claimingNft ? "Approving" : "Approve"}
                        
                      </StyledButton>
                      <s.SpacerSmall />
                      <StyledHR></StyledHR>
                    </s.Container>

                  </>
                )}
            <s.SpacerMedium />

            { blockchain.account === "" || blockchain.smartContract === null ? (
            <>
            </>
          ) : (
            <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                  </s.Container>
                ) : (
                  <>
                     <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                    
                    Donk Swap Tokens Earned : {RewardMSG}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {UnStakeMSG} {StakeMSG} {HavestMSG} {UnStakeAllMSG}
                    </s.TextDescription>
                    <s.SpacerMedium />


                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <input style={{ width: 125, height: 25}}
                          onChange={ e => settokens(e.target.value)}
                          value={tokens}
                          placeholder="TokenID"
                          type="number"
                          min="1"
                          max="10000"
                          ref={tokensInputRef}
                        /> 
                    </s.Container>
                    <s.SpacerMedium />
                    <s.Container ai={"flex-start"} jc={"center"} fd={"row"}>

      
                      
                    <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          Stake();
                          getData();
                          setUnMSG(null);
                          setHarvestMSG(null);
                          setUnStakeAllMSG(null);
                        }}
                      >
                        {StakeNFT ? "Staking..." : "Stake"}
                        
                      </StyledButton>
                      <s.SpacerSmall />
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          StakeAll();
                          getData();
                          setUnMSG(null);
                          setHarvestMSG(null);
                          setUnStakeAllMSG(null);
                        }}
                      >
                        {UnStakeNFT ? "Staking..." : "Stake All"}
                        
                      </StyledButton>
                      <s.SpacerSmall />
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          unstakeallnfts();
                          getData();
                          setMSG(null);
                          setHarvestMSG(null);
                        }}
                      >
                        {UnStakeAllNFT ? "Unstaking" : "UnstakeAll"}
                        
                      </StyledButton>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"column"}>
                        
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          Harvest();
                          getData();
                          setUnMSG(null);
                          setMSG(null);
                        }}
                        style={{ width:200 }}
                      >
                        {ClaimReward ? "Claiming..." : "Claim Your Reward"}
                        
                      </StyledButton>
                      <s.SpacerMedium />
                    </s.Container>

                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                     Your Staked NFTs: {CheckMSG}
                    </s.TextDescription>
                    <s.SpacerXSmall/>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                     Total Staked NFTs: {data.tokensStaked}
                    </s.TextDescription>
                    <s.SpacerXSmall/>
                    <StyledButton
                style={{
                  margin: "5px",
                  width:175, 
                }}
                onClick={(e) => {
                  window.open(CONFIG.BUTTON_HOME);
                } }
              >DonkSwap HOME

              </StyledButton>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      <StyledLink2 href={CONFIG.SCAN_LINK}>
                        BSCSCAN
                      </StyledLink2>
                    </s.TextDescription>
                  </>
                )}
            <s.SpacerMedium />
          </>)}

          
          </s.BOX>

          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg2
              alt={"example"}
              src={"/config/images/chip.gif"} />
          </s.Container>
  </ResponsiveWrapper>


<BrowserView>

            <ResponsiveWrapper>
            <View style={{ position: 'fixed', left: 0, bottom: 0, }}>
              <s.Container flex={1}>
                <a href={"https://t.me/+mF05PQOhrKtjZWI5"}>
                  <StyledImg3
                    alt={"https://t.me/+mF05PQOhrKtjZWI5"}
                    src={"/config/images/KANDO4YOUx.png"} />
                </a>
              </s.Container>
              </View>
              </ResponsiveWrapper>
              </BrowserView>

<MobileView>
              <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg2
              alt={"example"}
              src={"/config/images/KANDO4YOUx.png"} />
          </s.Container>
          </MobileView>



          <s.SpacerLarge />
 
      
        
       
        <s.SpacerMedium />

      </s.Container>


      
    </s.Screen>
    
  );
}

export default App;
